

const LightTheme = {
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 480,
            sm: 640,
            md: 1020,
            lg: 1280,
            xl: 1600
        },
        unit: 'px',
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h3',
                    h5: 'h4',
                    h6: 'h5',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'p',
                    body2: 'p',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#979797"
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#07689F",
                    borderWidth: 2
                },
                "&$focused $notchedOutline": {
                    borderColor: "#07689F"
                }
            },
            notchedOutline: {}
        },
        MuiSelect: {
            icon: {
                fill: "#979797"
            }
        },
    },
    direction: 'ltr',
    palette: {
        mode: 'light',
        app: {
            primary: "#07689F",
            primaryContrastText: "#FFFFFF",
            background: "#E3E7EB",
            backgroundContrastText: "rgba(0,0,0,0.70)",
        },
        common: {
            black: '#000000',
            white: '#FFFFFF'
        },
        primary: {
            main: "#07689F",
            light: "#58A6FF",
            dark: "#0A3F79",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#DA3A3B",
            contrastText: "#FFFFFF"
        },
        warning: {
            main: "#FF9F1C", // #FF9800
            contrastText: "#394453"
        },
        disabled: {
            main: "#ebeef1",
            contrastText: "rgba(105, 114, 130, 1)"
        },
        info: {
            main: "#07689F",
            contrastText: "#FFFFFF"
        },
        success: {
            main: "#2D9639",
            contrastText: "#FFFFFF"
        },
        grey: {
            50: "#FAFAFA",
            100: "#F5F5F5",
            200: "#EEEEEE",
            300: "#E0E0E0",
            400: "#BDBDBD",
            500: "#9E9E9E",
            555: "#979797",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#F5F5F5",
            A200: "#EEEEEE",
            A400: "#BDBDBD",
            A700: "#616161"
        },
        text: {
            primary: "rgba(26, 27, 37, 1)",
            secondary: "rgba(105, 114, 130, 1)",
            disabled: "rgba(0,0,0,0.3)",
            icon: "rgba(0,0,0,0.5)",
        },
        background: {
            primary: "#FFFFFF",
            secondary: "#F6F8FA",
            tertiary: "#EAEBEC",
            quaternary: "#DADEE0"
        },
        action: {
            active: "rgba(0,0,0,0.54)",
            hover: "rgba(0,0,0,0.04)",
            hoverOpacity: 0.08,
            selected: "rgba(0,0,0,0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0,0,0,0.26)",
            disabledBackground: "rgba(0,0,0,0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0,0,0,0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.24
        }
    },
    shape: {
        borderRadius: 5,
        scrollbarWidth: 8,
        appBarHeight: "54px",
        appBarMobileHeight: "44px",
        appBarMobileTallHeight: "70px"
    },
    borderRadius: {
        sm: 5,
        md: 10,
        lg: 15,
        xl: 25
    },
    boxShadows: {
        none: 'none',
        sm: "0px 2px 1px -1px rgba(0, 0, 0, 0.70)",
        md: "0px 3px 1px -2px rgba(0, 0, 0, 0.70)",
        lg: "0px 3px 2px -2px rgba(0, 0, 0, 0.70)",
        xl: "0px 4px 2px -1px rgba(0, 0, 0, 0.70)",
        modal: "0px 0px 10px rgba(0, 0, 0, 0.70)",
        1: "1px 1px 1px rgba(0, 0, 0, 0.70)",
        2: "2px 2px 2px rgba(0, 0, 0, 0.70)",
        inset: "inset 0px 0px 2px rgba(0,0,0,0.70)",
        color: "rgba(0, 0, 0, 0.70)"
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600,
        fontWeightBold: 700,
        h1: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 300,
            fontSize: "4rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
        },
        h2: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 300,
            fontSize: "3rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "2rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "1.65rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "1.35rem",
            lineHeight: 1.5,
            letterSpacing: "0em",
        },
        h6: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        body2: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "0.9rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em"
        },
        tooltip: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em"
        },
        caption: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em"
        },
        overline: {
            fontFamily: "Open Sans, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "0.8rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em"
        }
    },
    transitions: {
        easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195
        }
    },
    variables: {
        mobileTallHeight: 800,
        headerDesktopHeight: 58,
        headerMobileHeight: 48,
        headerMobileTallHeight: 64,
        headerMobileSecondaryHeight: 56,
        sidebarFullWidth: 220,
        sidebarMediumWidth: 70,
        sidebarMobileWidth: 0,
        filterDrawerWidth: 320,
    },
    zIndex: {
        tableSticky: 500,
        mobileStepper: 1000,
        appBar: 1050,
        drawer: 1200,
        modal: 1300,
        dropdownMenu: 1350,
        snackbar: 1400,
        tooltip: 1500,
    }
};

export default LightTheme;