
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as FilterOperatorTypeIdenifiers from '../../../constants/filtering/operators/FilterOperatorTypeIdentifiers';
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';
import * as GraphQLQueryTypes from '../../types/GraphQLQueryTypes';
import * as GraphQLTypes from '../../types/GraphQLTypes';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';


const AccountListViewsData = [

    // Active Customers
    {
        object: null,
        object_identifier: 'accounts',
        public_id: uniqid('view_'),
        sync_token: 1,
        identifier: identifier('Active Customers').toLowerCase(),
        type: "list_view",
        name: 'Active Customers',
        description: 'All active customer accounts that do business with the company.',
        sort_order: 1, 
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "accounts.name",
        sql_sort_field: ["accounts.name", "name.display"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        api_request: {
            [GraphQLQueryTypes.GetAccounts]: {
                inputs: {
                    company_id: GraphQLTypes.ID,
                    user_id: GraphQLTypes.ID,
                    list_view_id: GraphQLTypes.ID,
                    sort_by: GraphQLTypes.Array_String,
                    sort_direction: GraphQLTypes.String,
                    sort_join: GraphQLTypes.Array_String,
                    sort_method: GraphQLTypes.String,
                    sort_nulls_last: GraphQLTypes.Boolean,
                    filter_by_viewer: GraphQLTypes.Boolean,
                    filters: GraphQLTypes.Array_Filters
                },
                outputs: {
                    name: {
                        display: GraphQLTypes.String
                    },
                    balance: GraphQLTypes.BigInt,
                    primary_property: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    primary_contact: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    phone: {
                        number: GraphQLTypes.Int,
                        national: GraphQLTypes.String,
                        international: GraphQLTypes.String,
                        country: GraphQLTypes.String
                    },
                    email: {
                        address: GraphQLTypes.String
                    },
                    owner: {
                        id: GraphQLTypes.ID,
                        user_id: GraphQLTypes.ID,
                        name: {
                            display: GraphQLTypes.String
                        }
                    },
                    id: GraphQLTypes.ID,
                    type: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String
                    },
                    account_id: GraphQLTypes.ID,
                    sync_token: GraphQLTypes.Int,
                    time_zone: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    currency: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    language: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    company: GraphQLTypes.ID,
                    settings: GraphQLTypes.JSON,
                    custom_fields: GraphQLTypes.JSON,
                    metadata: GraphQLTypes.JSON,
                    suggested_action: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        short_label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        icon: GraphQLTypes.String,
                        sort_order: GraphQLTypes.Int,
                        action_type: GraphQLTypes.String,
                        use_type: GraphQLTypes.String,
                        description: GraphQLTypes.String,
                        help_text: GraphQLTypes.String
                    }
                }
            }
        },
        filters: [
            {
                fields: ["accounts.type"],
                field_types: [FieldTypes.TYPE_DROPDOWN],
                operator: FilterOperatorTypeIdenifiers.EQUAL,
                values: [
                    {
                        type: "value",
                        label: 'Customer',
                        input: 'customer',
                        data: null,
                        data_type: DatabaseTypes.BIGINT,
                        status: 'valid'
                    }
                ],
                is_hidden: false
            },
            {
                fields: ["accounts.is_active"],
                field_types: [FieldTypes.CHECKBOX],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            }
        ],
        search_value: "",
        visible_fields: [
            {
                label: "Name",
                identifier: 'accounts.name',
                display_fields: ['accounts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: true
            },
            {
                label: "Balance",
                identifier: 'accounts.balance',
                display_fields: ['accounts.balance'],
                display_format: 'currency',
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: "Primary Property",
                identifier: 'accounts.primary_property',
                display_fields: ['accounts.primary_property', 'properties.address', 'address.display'],
                display_format: null,
                help_text: '',
                width: 250,
                is_locked: false
            },
            {
                label: "Primary Contact",
                identifier: 'accounts.primary_contact',
                display_fields: ['accounts.primary_contact', 'contacts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Phone",
                identifier: 'accounts.phone',
                display_fields: ['accounts.phone', 'phone.formatted'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Email",
                identifier: 'accounts.email',
                display_fields: ['accounts.email', 'email.address'],
                display_format: null,
                help_text: '',
                width: 160,
                is_locked: false
            },
            {
                label: "Owner",
                identifier: 'accounts.owner',
                display_fields: ['accounts.owner', 'users.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            }
        ],
        hidden_fields: [
            "accounts.id",
            "accounts.type",
            "accounts.account_id",
            "accounts.sync_token",
            "accounts.time_zone",
            "accounts.currency",
            "accounts.language",
            "accounts.company",
            "accounts.settings",
            "accounts.custom_fields",
            "accounts.metadata",
            "accounts.suggested_action"
        ],
        batch_actions: [
            "accounts.batch_edit",
            "accounts.batch_email",
            "accounts.batch_archive"
        ],
        item_actions: [
            "accounts.edit",
            "accounts.new_contact",
            "accounts.new_property",
            "accounts.call_phone",
            "accounts.send_email",
            "accounts.archive"
        ],
        more_actions: [
            "accounts.view_property",
            "accounts.view_contact",
            "accounts.get_directions",
            "accounts.duplicate",
            "accounts.delete"
        ],
        pagination_amount: 25
    },
    // My Customers
    {
        object: null,
        object_identifier: 'accounts',
        public_id: uniqid('view_'),
        sync_token: 1,
        identifier: identifier('My Customers').toLowerCase(),
        type: "list_view",
        name: 'My Customers',
        description: 'All customer accounts that the runtime user is the owner for.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "accounts.name",
        sql_sort_field: ["accounts.name", "name.display"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        api_request: {
            [GraphQLQueryTypes.GetAccounts]: {
                inputs: {
                    company_id: GraphQLTypes.ID,
                    user_id: GraphQLTypes.ID,
                    list_view_id: GraphQLTypes.ID,
                    sort_by: GraphQLTypes.Array_String,
                    sort_direction: GraphQLTypes.String,
                    sort_join: GraphQLTypes.Array_String,
                    sort_method: GraphQLTypes.String,
                    sort_nulls_last: GraphQLTypes.Boolean,
                    filter_by_viewer: GraphQLTypes.Boolean,
                    filters: GraphQLTypes.Array_Filters
                },
                outputs: {
                    name: {
                        display: GraphQLTypes.String
                    },
                    balance: GraphQLTypes.BigInt,
                    primary_property: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    primary_contact: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    phone: {
                        number: GraphQLTypes.Int,
                        national: GraphQLTypes.String,
                        international: GraphQLTypes.String,
                        country: GraphQLTypes.String
                    },
                    email: {
                        address: GraphQLTypes.String
                    },
                    owner: {
                        id: GraphQLTypes.ID,
                        user_id: GraphQLTypes.ID,
                        name: {
                            display: GraphQLTypes.String
                        }
                    },
                    id: GraphQLTypes.ID,
                    type: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String
                    },
                    account_id: GraphQLTypes.ID,
                    sync_token: GraphQLTypes.Int,
                    time_zone: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    currency: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    language: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    company: GraphQLTypes.ID,
                    settings: GraphQLTypes.JSON,
                    custom_fields: GraphQLTypes.JSON,
                    metadata: GraphQLTypes.JSON,
                    suggested_action: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        short_label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        icon: GraphQLTypes.String,
                        sort_order: GraphQLTypes.Int,
                        action_type: GraphQLTypes.String,
                        use_type: GraphQLTypes.String,
                        description: GraphQLTypes.String,
                        help_text: GraphQLTypes.String
                    }
                }
            }
        },
        filters: [
            {
                fields: ["accounts.type"],
                field_types: [FieldTypes.TYPE_DROPDOWN],
                operator: FilterOperatorTypeIdenifiers.EQUAL,
                values: [
                    {
                        type: "value",
                        label: 'Customer',
                        input: 'customer',
                        data: null,
                        data_type: DatabaseTypes.BIGINT,
                        status: 'valid'
                    }
                ],
                is_hidden: false
            },
            {
                fields: ["contacts.owner", "users.id"],
                field_types: ["lookup_relationship", "id"],
                operator: "equal",
                values: [
                    {
                        type: "relationship",
                        label: 'Me',
                        input: 'user.id',
                        data: null,
                        data_type: 'relationship',
                        status: 'valid'
                    }
                ],
                is_hidden: false
            },
            {
                fields: ["contacts.is_active"],
                field_types: ["checkbox"],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            }
        ],
        search_value: "",
        visible_fields: [
            {
                label: "Name",
                identifier: 'accounts.name',
                display_fields: ['accounts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: true
            },
            {
                label: "Balance",
                identifier: 'accounts.balance',
                display_fields: ['accounts.balance'],
                display_format: 'currency',
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: "Primary Property",
                identifier: 'accounts.primary_property',
                display_fields: ['accounts.primary_property', 'properties.address', 'address.display'],
                display_format: null,
                help_text: '',
                width: 250,
                is_locked: false
            },
            {
                label: "Primary Contact",
                identifier: 'accounts.primary_contact',
                display_fields: ['accounts.primary_contact', 'contacts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Phone",
                identifier: 'accounts.phone',
                display_fields: ['accounts.phone', 'phone.formatted'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Email",
                identifier: 'accounts.email',
                display_fields: ['accounts.email', 'email.address'],
                display_format: null,
                help_text: '',
                width: 160,
                is_locked: false
            },
            {
                label: "Owner",
                identifier: 'accounts.owner',
                display_fields: ['accounts.owner', 'users.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            }
        ],
        hidden_fields: [
            "accounts.id",
            "accounts.type",
            "accounts.account_id",
            "accounts.sync_token",
            "accounts.time_zone",
            "accounts.currency",
            "accounts.language",
            "accounts.company",
            "accounts.settings",
            "accounts.custom_fields",
            "accounts.metadata",
            "accounts.suggested_action"
        ],
        batch_actions: [
            "accounts.batch_edit",
            "accounts.batch_email",
            "accounts.batch_archive"
        ],
        item_actions: [
            "accounts.edit",
            "accounts.new_contact",
            "accounts.new_property",
            "accounts.call_phone",
            "accounts.send_email",
            "accounts.archive"
        ],
        more_actions: [
            "accounts.view_property",
            "accounts.view_contact",
            "accounts.get_directions",
            "accounts.duplicate",
            "accounts.delete"
        ],
        pagination_amount: 25
    },
    // New Accounts
    {
        object: null,
        object_identifier: 'accounts',
        public_id: uniqid('view_'),
        sync_token: 1,
        identifier: identifier('New Accounts').toLowerCase(),
        name: 'New Accounts',
        description: 'All new accounts created in the last 7 days.',
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_personal: false,
        is_company: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "accounts.last_updated_at",
        sql_sort_field: ["accounts.last_updated_at"],
        sort_method: SortingMethods.CHRONOLOGICAL,
        sort_direction: SortingDirectionIdentifiers.DESCENDING,
        sort_join: null,
        filter_by_viewer: "all",
        filters: [
            {
                fields: ["accounts.created_at"],
                field_types: [FieldTypes.DATE_TIME],
                operator: FilterOperatorTypeIdenifiers.RECENT_RANGE,
                values: [
                    {
                        type: "value",
                        label: '7',
                        input: '7',
                        status: 'valid',
                        data: 7,
                        data_type: DatabaseTypes.INTEGER,
                    },
                    {
                        type: "value",
                        input: "days",
                        label: "Day(s)",
                        status: "valid",
                        data: "days",
                        data_type: DatabaseTypes.TEXT,
                    }
                ],
                is_hidden: false
            },
            {
                fields: ["contacts.is_active"],
                field_types: ["checkbox"],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            },
        ],
        search_value: '',
        visible_fields: [
            {
                label: 'Name',
                identifier: "accounts.name",
                display_fields: ['accounts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: "accounts.type",
                display_fields: ['accounts.type', 'object_types.label'],
                display_format: null,
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: 'Primary Property',
                identifier: "accounts.primary_property",
                display_fields: ['accounts.primary_property', 'properties.address', 'address.display'],
                display_format: null,
                help_text: '',
                width: 250,
                is_locked: false
            },
            {
                label: 'Primary Contact',
                identifier: "accounts.primary_contact",
                display_fields: ['accounts.primary_contact', 'contacts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: 'Rating',
                identifier: "accounts.rating",
                display_fields: ['accounts.rating', 'dropdowns.label'],
                display_format: null,
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: 'Source',
                identifier: "accounts.source",
                display_fields: ['accounts.source', 'dropdowns.label'],
                display_format: null,
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: 'Owner',
                identifier: "accounts.owner",
                display_fields: ['accounts.owner', 'users.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: "accounts.created_at",
                display_fields: ['accounts.created_at'],
                display_format: 'MMM Do, YYYY h:mm A',
                help_text: '',
                width: 180,
                is_locked: false
            },
        ],
        hidden_fields: [
            "accounts.id",
            "accounts.account_id",
            "accounts.sync_token",
            "accounts.time_zone",
            "accounts.currency",
            "accounts.language",
            "accounts.company",
            "accounts.settings",
            "accounts.custom_fields",
            "accounts.metadata",
            "accounts.suggested_action"
        ],
        batch_actions: [
            "accounts.batch_edit",
            "accounts.batch_email",
            "accounts.batch_archive"
        ],
        item_actions: [
            "accounts.edit",
            "contacts.new",
            "properties.new",
            "accounts.call_phone",
            "accounts.send_email",
            "accounts.archive"
        ],
        more_actions: [
            "accounts.view_property",
            "accounts.view_contact",
            "accounts.get_directions",
            "accounts.duplicate",
            "accounts.delete"
        ],
        pagination_amount: 25
    },
    // Active Customers
    {
        object: null,
        object_identifier: 'accounts',
        public_id: uniqid('view_'),
        sync_token: 1,
        identifier: identifier('ALL Customers').toLowerCase(),
        type: "list_view",
        name: 'ALL Customers',
        description: 'All active accounts that do business with the company.',
        sort_order: 1, 
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "accounts.name",
        sql_sort_field: ["accounts.name", "name.display"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        api_request: {
            [GraphQLQueryTypes.GetAccounts]: {
                inputs: {
                    company_id: GraphQLTypes.ID,
                    user_id: GraphQLTypes.ID,
                    list_view_id: GraphQLTypes.ID,
                    sort_by: GraphQLTypes.Array_String,
                    sort_direction: GraphQLTypes.String,
                    sort_join: GraphQLTypes.Array_String,
                    sort_method: GraphQLTypes.String,
                    sort_nulls_last: GraphQLTypes.Boolean,
                    filter_by_viewer: GraphQLTypes.Boolean,
                    filters: GraphQLTypes.Array_Filters
                },
                outputs: {
                    name: {
                        display: GraphQLTypes.String
                    },
                    balance: GraphQLTypes.BigInt,
                    primary_property: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    primary_contact: {
                        id: GraphQLTypes.ID,
                        property_id: GraphQLTypes.ID,
                        address: GraphQLTypes.Address
                    },
                    phone: {
                        number: GraphQLTypes.Int,
                        national: GraphQLTypes.String,
                        international: GraphQLTypes.String,
                        country: GraphQLTypes.String
                    },
                    email: {
                        address: GraphQLTypes.String
                    },
                    owner: {
                        id: GraphQLTypes.ID,
                        user_id: GraphQLTypes.ID,
                        name: {
                            display: GraphQLTypes.String
                        }
                    },
                    id: GraphQLTypes.ID,
                    type: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String
                    },
                    account_id: GraphQLTypes.ID,
                    sync_token: GraphQLTypes.Int,
                    time_zone: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    currency: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    language: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    company: GraphQLTypes.ID,
                    settings: GraphQLTypes.JSON,
                    custom_fields: GraphQLTypes.JSON,
                    metadata: GraphQLTypes.JSON,
                    suggested_action: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        short_label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        icon: GraphQLTypes.String,
                        sort_order: GraphQLTypes.Int,
                        action_type: GraphQLTypes.String,
                        use_type: GraphQLTypes.String,
                        description: GraphQLTypes.String,
                        help_text: GraphQLTypes.String
                    }
                }
            }
        },
        filters: [
            {
                fields: ["accounts.is_active"],
                field_types: [FieldTypes.CHECKBOX],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            }
        ],
        search_value: "",
        visible_fields: [
            {
                label: "Name",
                identifier: 'accounts.name',
                display_fields: ['accounts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: true
            },
            {
                label: "Balance",
                identifier: 'accounts.balance',
                display_fields: ['accounts.balance'],
                display_format: 'currency',
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: "Type",
                identifier: 'accounts.type',
                display_fields: ['accounts.type', "object_types.label"],
                display_format: null,
                help_text: '',
                width: 120,
                is_locked: false
            },
            {
                label: "Primary Property",
                identifier: 'accounts.primary_property',
                display_fields: ['accounts.primary_property', 'properties.address', 'address.display'],
                display_format: null,
                help_text: '',
                width: 250,
                is_locked: false
            },
            {
                label: "Primary Contact",
                identifier: 'accounts.primary_contact',
                display_fields: ['accounts.primary_contact', 'contacts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Phone",
                identifier: 'accounts.phone',
                display_fields: ['accounts.phone', 'phone.formatted'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            },
            {
                label: "Email",
                identifier: 'accounts.email',
                display_fields: ['accounts.email', 'email.address'],
                display_format: null,
                help_text: '',
                width: 160,
                is_locked: false
            },
            {
                label: "Owner",
                identifier: 'accounts.owner',
                display_fields: ['accounts.owner', 'users.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 180,
                is_locked: false
            }
        ],
        hidden_fields: [
            "accounts.id",
            "accounts.type",
            "accounts.account_id",
            "accounts.sync_token",
            "accounts.time_zone",
            "accounts.currency",
            "accounts.language",
            "accounts.company",
            "accounts.settings",
            "accounts.custom_fields",
            "accounts.metadata",
            "accounts.suggested_action"
        ],
        batch_actions: [
            "accounts.batch_edit",
            "accounts.batch_email",
            "accounts.batch_archive"
        ],
        item_actions: [
            "accounts.edit",
            "accounts.new_contact",
            "accounts.new_property",
            "accounts.call_phone",
            "accounts.send_email",
            "accounts.archive"
        ],
        more_actions: [
            "accounts.view_property",
            "accounts.view_contact",
            "accounts.get_directions",
            "accounts.duplicate",
            "accounts.delete"
        ],
        pagination_amount: 25
    },
];

export default AccountListViewsData;